<template>
    <div class="content">
        <div class="search-attr">
            <div>
                <!-- <a-input allowClear v-model="searchParams.userName" size='small' style="width:150px;margin:5px;" placeholder='作者'></a-input>
                <a-input allowClear v-model="searchParams.productName" size='small' style="width:150px;margin:5px;" placeholder='内容'></a-input>
            
                <a-button @click="search" size='small' style="margin-left:10px;">搜索</a-button> -->
            </div>

        </div>
        <div class="table-content" >
            <a-table @change="changePage" :pagination='pagination' :loading='loading' :columns="columns" :data-source="data" :rowKey="(record,n)=>n">
                <div slot="images" slot-scope="row">
                    <div style='max-width:100px;max-height:100px;display:flex;justify-content:center;align-items:center;'>
                        <img @click="previewImg(row)" style='max-width:100px;max-height:100px;' :src='row.coverUrl'/>
                    </div>
                </div>

                 <div slot="setup" slot-scope="row">
                     
                     <a v-if="row.isPush == 0" @click="pushMsgAction(row)" style='margin-right:5px'>推送</a>
                    <!-- <a @click="openModal(row,1)" style='margin-right:5px'>编辑</a> -->

                    <!-- <a-popconfirm
                        title="确认删除么?"
                        ok-text="Yes"
                        cancel-text="No"
                        @confirm="remove(row.productNo)"
                    >
                    <a style='margin-left:5px'>删除</a>
                    </a-popconfirm> -->

                </div>

            </a-table>
        </div>

    </div>
</template>

<script>
import moment from 'moment'
import {mapState} from 'vuex'
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
export default {
    data(){
        return {
            columns:[
                {title:'图片',scopedSlots:{customRender:'images'}},
                {title:'群',dataIndex:'groupName'},
                {title:'卖家',dataIndex:'sellerUserName'},
                {title:'商品名称',dataIndex:'productShelvesName'},
                {title:'商品价格',dataIndex:'productShelvesPrice'},
                {title:'商品发布时间',dataIndex:'productShelvesPublishTime'},
                {title:'询价买家',dataIndex:'buyerUserName'},
                {title:'买家uId',dataIndex:'buyerUserId'},
                {title:'询价价格',dataIndex:'offerPrice'},
                {title:'询价时间',dataIndex:'createTime'},
                {title:'是否推送',dataIndex:'isPush'},
                {title:'操作',align:'center',scopedSlots: { customRender: 'setup' }},
            ],
            data:[],
            times:[],
            messages:[],
            layout:{
                labelCol:{span:4},//标签占5份  共24份
                wrapperCol:{span:20},
            },
            forms:{},
            visiblePrice:false,
            users:[],
            fileList:[],
            rules:{
    
            },
            visible:false,
            params:{pageNum:1,pageSize:10},
            IO:0,
            tagCurrent:'',
            pagination:{
                total:0,
                current:1,
                showTotal:(total)=>`共${total}条`,
            },
            loading:false,
            checkAll:false,
            indeterminate:false,
            IO:0,
            searchTime:[],
            currency_big_list:[],
            searchParams:{
                userName:'',
                coinKindName:'',
            },
            previewVisible: false,
            previewImage: '',
            inputValue:'',
            inputVisible:false,
            imgKey:'',
            kindList:[],
             paginationModal:{
                total:0,
                current:1,
                showTotal:(total)=>`共${total}条`,
            },
            loadingModal:false,
            
            modalParams:{},
        }
    },
    created(){
        const {userId} = this.$route.query
        if(userId){
            this.searchParams.userId = userId
        }
        this.getData({...this.params,...this.searchParams})
       
    },
    mounted(){
        addEventListener('paste',(e)=>{
            if(this.visible){
                this.untils.pasteImg(e,1,this.paseImgs)
            }
        })

    },
    computed:{
        ...mapState(['ip'])
    },
    methods:{
        moment,
        openModal(row,type){
            this.visible = true
            this.imgKey = Math.random()
            this.fileList = []
            if(type){
                this.IO = 1
                this.forms = JSON.parse(JSON.stringify(row))
                const {coverUrls} = row || {coverUrls:[]}
                if(coverUrls&&coverUrls.length>0){
                    this.fileList = coverUrls.map((items,i)=>{
                        const obj = {
                            uid:i,name: 'image',status: 'done',url:items,
                        }
                        return obj
                    })

                }

            }else{
                this.IO = 0
                this.forms = {
                    tags:[],
                    coverUrls:[],
                }
            }
        },
        copy_text(text){
            let oInput = document.createElement('input')
            oInput.value = text
            document.body.appendChild(oInput)
            oInput.select()
            document.execCommand("Copy")
            oInput.remove()
            this.$message.success('已复制进剪切板')
        },
        copy(detail){
            this.copy_text(detail)
        },
        hideModal(){
            this.$refs.ruleForm.validate(valid => {
                if(valid){
                    this.submit(this.forms)
                }else{
                    return false;
                }
            });
        },
        resetForms(){
            this.forms = {}
            this.$refs.ruleForm.resetFields();
        },
        async getKinds(){
            const res = await this.axios('/dq_admin/kind/list')
        },
        submit(forms){
            const postData = JSON.parse(JSON.stringify(forms))
            const images = this.fileList.map(row=>row.url)
            postData.coverUrls = images
            const post_url = this.IO ? '/dq_admin/product/edit' : '/dq_admin/product/addProduct'
            this.axios.post(post_url,{...postData}).then(res=>{
                if(res.status == '200'){
                    this.$message.success(res.message)
                    this.visible = false
                    this.getData({...this.params,...this.searchParams})
                }
            })
        },
        async getData(params){
            this.loading = true
            const res = await this.axios('/dq_admin/productShelves/list',{params:params})
            this.loading = false
            const {records,total} = res.data
            this.data = records
            this.pagination.total = total
        },
        async getUsers(){
            const res = await this.axios('/dq_admin/user/list')
            const {data} = res
            this.users = data
        },
        search(){
            const result = this.times.map(row=>{
                const time = new Date(row._d).toLocaleDateString().replace(/\//g,"-")
                return time
            })
            if(result&&result.length>0){
                 [this.searchParams.startTime,this.searchParams.endTime] = [...result]
            }
            this.params.pageNum = 1
            this.getData({...this.params,...this.searchParams})
        },
        userIdSearch(id){
            this.params.pageNum = 1
            this.searchParams.userId = id
            this.getData({...this.params,...this.searchParams})
        },
        async remove(n){
            const res = await this.axios.post('/dq_admin/product/delProduct',{productNo:n})
            if(res.status == '200'){
                this.$message.success(res.message)
                this.getData({...this.params,...this.searchParams})
            }
        },

        changePage(page){
            const {current,pageSize} = page
            this.pagination.current = current
            this.params.pageNum = current
            this.params.pageSize = pageSize
            this.getData({...this.params,...this.searchParams})
        },
        handleCancel() {
            this.previewVisible = false;
        },
        async handlePreview(file) {
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj);
            }
            this.previewImage = file.url || file.preview;
            this.previewVisible = true;
        },
        handleChange({fileList}){
            fileList.map((row)=>{
                try{
                    if(row.response.data){
                        row.url = row.response.data
                    }
                }catch(e){

                }
            })
            this.fileList = fileList
        },
        searchData(inner){
            this.axios('/dq_admin/kind/list',{params:{
                coinName: inner
            }}).then(res=>{
                if(res.status == '200'){
                    const {records} = res.data
                    this.currency_big_list = records
                }
            })
        },
        changeTime(time){
            const {_d} = time
            this.forms.createTime = new Date(_d).toLocaleDateString().replace(/\//g,"-")
        },
        showInput() {
            this.inputVisible = true;
            this.$nextTick(function() {
                this.$refs.input.focus();
            });
        },
        handleInputChange(e) {
            this.inputValue = e.target.value;
        },

        handleInputConfirm() {
            const inputValue = this.inputValue;
            const tags = this.forms.tags
            if (inputValue && tags.indexOf(inputValue) === -1) {
                tags.push(inputValue);
            }
            Object.assign(this, {
                inputVisible: false,
                inputValue: '',
            });
        },
        
        previewImg(row){
            if(row.coverUrls&&row.coverUrls.length>0){
                this.$previewImg({
                    list:row.coverUrls.reduce((pre,cur)=>{
                        const obj = {img_url:cur}
                        pre = pre.concat(obj)
                        return pre
                    },[]),
                    
                    baseImgField:'img_url',
                    showMute:false,
                })
            }
        },
        paseImgs(type,data,row){
            const len = this.fileList.length+1
            const obj = {uid:len,name: 'image',status: 'done',url:data,}
            this.fileList.push(obj)
        },
        pushMsgAction(row){
            const res =  this.axios('/dq_admin/productShelves/pushMsg',{params:{productShelvesOfferPriceId:row.id}})
            if(res.status == '200'){
                this.$message.success(res.message)
                this.getData({...this.params,...this.searchParams})
            }
        },
        cancelModal(){
            this.messages = []
        },
        
    }
}
</script>

<style lang="scss" scoped>
.search-attr{display: flex;justify-content: space-between;padding-bottom:20px;}
.content{display: flex;flex-direction: column;
    .table-content{
        flex:1;
        overflow-y: auto;
    }
}
</style>