var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "table-content" },
      [
        _c("a-table", {
          attrs: {
            pagination: _vm.pagination,
            loading: _vm.loading,
            columns: _vm.columns,
            "data-source": _vm.data,
            rowKey: function(record, n) {
              return n
            }
          },
          on: { change: _vm.changePage },
          scopedSlots: _vm._u([
            {
              key: "images",
              fn: function(row) {
                return _c("div", {}, [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "max-width": "100px",
                        "max-height": "100px",
                        display: "flex",
                        "justify-content": "center",
                        "align-items": "center"
                      }
                    },
                    [
                      _c("img", {
                        staticStyle: {
                          "max-width": "100px",
                          "max-height": "100px"
                        },
                        attrs: { src: row.coverUrl },
                        on: {
                          click: function($event) {
                            return _vm.previewImg(row)
                          }
                        }
                      })
                    ]
                  )
                ])
              }
            },
            {
              key: "setup",
              fn: function(row) {
                return _c("div", {}, [
                  row.isPush == 0
                    ? _c(
                        "a",
                        {
                          staticStyle: { "margin-right": "5px" },
                          on: {
                            click: function($event) {
                              return _vm.pushMsgAction(row)
                            }
                          }
                        },
                        [_vm._v("推送")]
                      )
                    : _vm._e()
                ])
              }
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "search-attr" }, [_c("div")])
  }
]
render._withStripped = true

export { render, staticRenderFns }